/* eslint-disable jsx-a11y/media-has-caption */
import React from "react"
import PropTypes from "prop-types"

import VideoBG from '../images/slomo-bird.mp4';

import "./layout.css"

const Layout = ({ children }) => {
  return (
    <div id="layout">
      <video src={VideoBG} autoPlay loop muted />
      <div id="content">
        <main>{children}</main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
